var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"pa-0",style:(("width:" + _vm.width + "px"))},_vm._l((_vm.menu),function(item){return _c('div',{key:item.key},[(item.submenu)?_c('v-menu',{ref:("" + (item.key)),refInFor:true,attrs:{"transition":"scroll-x-reverse-transition","close-on-content-click":false,"offset-x1":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-subtitle-2 text-none text-left py-2",attrs:{"color":"grey darken-3","tile":"","block":"","text":"","x-large":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$ml.get(item.title))+" "),_c('v-spacer'),(!!item.submenu)?_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-right')}}):_vm._e()],1)]}}],null,true)},[_c('v-card',{staticClass:"pa-0",attrs:{"elevation":"4","max-height":"600","tile":""}},[_c('v-app-bar',{staticClass:"pa-0",attrs:{"dense":"","color":_vm.color,"dark":_vm.dark,"flat":""}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.closeSubMenu(item.key)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-left')}})],1),(item.submenu)?_c('span',[_vm._v(_vm._s(_vm.$ml.get(item.submenu.title)))]):_vm._e()],1),(item.subList)?_c('v-list',{attrs:{"tile":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.selectSubItem(
                item.subList.filter,
                item.subList.title,
                item.subList.key
              )}}},[_c('v-list-item-title',{attrs:{"disabled":item.subList.disabled}},[_vm._v(" "+_vm._s(_vm.$ml.get(item.subList.title))+" ")])],1)],1):_vm._e(),_c('v-divider'),(item.submenu)?_c('v-list',{attrs:{"tile":"","loading":""}},[(
              item.submenu.title === 'org_units' &&
              !item.submenu.options.length
            )?_c('v-list-item-title',{staticClass:"mx-10"},[_vm._v(" "+_vm._s(_vm.$ml.get("hasNoValuesToShow"))+" ")]):_vm._l((_vm.getVisibleOptions(item.submenu.options)),function(subItem){return _c('v-list-item',{key:item.submenu.options.indexOf(subItem),attrs:{"to":_vm.hasTo(subItem),"href":_vm.hasHref(subItem)},on:{"click":function($event){return _vm.selectSubItem(
                subItem.type,
                subItem.title,
                subItem.key,
                subItem.filterValue
              )}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(subItem.no_translate ? subItem.title : _vm.$ml.get(subItem.title))+" ")]),(subItem.subtitle)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(subItem.subtitle)+" ")]):_vm._e()],1)],1)})],2):_vm._e()],1)],1):_c('v-btn',{staticClass:"text-subtitle-2 text-none text-left py-2",attrs:{"color":"grey darken-3","tile":"","block":"","text":"","x-large":""},on:{"click":function($event){return _vm.selectSubItem(item.filter, item.title, item.key)}}},[_vm._v(" "+_vm._s(_vm.$ml.get(item.title))+" "),_c('v-spacer')],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }